import { useGoogleOauthAssociate } from "./useGoogleOauthAssociate";
import { IUserResponse, useAssociateUserGoogleAccount } from "@/api";

/**
 * @hook useAssociateGoogleFlow
 */
const useAssociateGoogleFlow = (userData?: IUserResponse) => {
  // second - tell services to associate the google account with the user
  const { mutateAsync: associateAccount } = useAssociateUserGoogleAccount();

  // first - get the google oauth's verified user data
  return useGoogleOauthAssociate({
    onSuccess: associateAccount,
    userId: userData?.data?.id,
  });
};

export { useAssociateGoogleFlow };
