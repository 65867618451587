import Photographer from "../../public/svg/photographer.svg";
import {
  useAssociateUserGoogleAccount,
  useDisconnectUserGoogleAccount,
  useUserQuery,
} from "@/api";
import { GoogleButton, GoogleButtonConnected, SEO } from "@/components";
import { resolveDownloadLinks } from "@/components/Download/utils";
import type { DownloadLinks } from "@/components/Download/utils";
import { IS_GOOGLE_AUTH_ENABLED } from "@/config";
import { LayoutPrivate, UserDetailsForm, DeleteAccount } from "@/containers";
import { getUserDetails, hasUserConnectedGoogleAccount } from "@/helpers/user";
import { withAuth } from "@/hoc";
import { useGoogleOauthAssociate } from "@/hooks";
import { useAssociateGoogleFlow } from "@/hooks/google/useAssociateGoogleFlow";
import Image from "next/image";

type UserDetailsProps = {
  productDownloadLinks: DownloadLinks;
};

const UserDetails = ({ productDownloadLinks }: UserDetailsProps) => {
  const { data: userData, isLoading: isLoadingUser } = useUserQuery();
  const { mutateAsync: disconnectAccount } = useDisconnectUserGoogleAccount();
  const onAssociateAccount = useAssociateGoogleFlow(userData);
  const hasConnectedGoogleAccount = hasUserConnectedGoogleAccount(userData);

  const { firstName, lastName, email } = userData
    ? getUserDetails(userData)
    : { firstName: null, lastName: null, email: null };

  return (
    <LayoutPrivate
      title="Account Details"
      testId="page-user-details"
      productDownloadLinks={productDownloadLinks}
    >
      <SEO title="Account Details | Narrative" />
      <div className="md:max-w-110">
        <h2 className="text-h5 font-semibold">Personal information</h2>
        <div className="mt-8">
          <UserDetailsForm
            hasConnectedGoogleAccount={!!hasConnectedGoogleAccount}
            user={userData}
            isLoading={isLoadingUser}
          />
        </div>
        {IS_GOOGLE_AUTH_ENABLED ? (
          <div className="mt-15">
            <h2 className="text-h5 font-semibold">
              {hasConnectedGoogleAccount ? "Connected" : "Connect"} Google
              account
            </h2>
            <div className="mt-4">
              {hasConnectedGoogleAccount ? (
                <GoogleButtonConnected onClick={disconnectAccount}>
                  {firstName || lastName
                    ? `${firstName} ${lastName}`.trim()
                    : email}
                </GoogleButtonConnected>
              ) : (
                <GoogleButton onClick={onAssociateAccount}>
                  Connect a Google Account
                </GoogleButton>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className="hidden absolute bottom-0 right-0 mb-10 mr-6 xl:block">
        <Image src={Photographer} alt="" width="379" height="400" priority />
      </div>
    </LayoutPrivate>
  );
};

export const getStaticProps = async () => {
  const productDownloadLinks = await resolveDownloadLinks();

  return {
    props: {
      productDownloadLinks,
    },
  };
};

export default withAuth(UserDetails);
